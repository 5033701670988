<template>
	<div v-if="dialogVisible">
		<el-dialog :title="!ruleForm.id?'新增会议室':'修改会议室'" :visible.sync="dialogVisible" width="40%"
			:before-close="resetform">
			<div class="addbox">
				<div class="left_info">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="会议室名称" prop="name">
							<el-input v-model="ruleForm.name" placeholder="请输入会议室名称"></el-input>
						</el-form-item>
						<el-form-item label="会议室地址" prop="address">
							<el-input v-model="ruleForm.address" placeholder="请输入会议室地址"></el-input>
						</el-form-item>
						<el-form-item label="坐标" prop="zuobiao">
							<el-input v-model="ruleForm.zuobiao" placeholder="输入经纬度,示例:116.37,40.31">
								<template slot="append">
									<el-button type="primary" @click="openmap">打开地图</el-button>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="联系人">
							<el-input v-model="ruleForm.username" placeholder="输入联系人">
								
							</el-input>
						</el-form-item>
						<el-form-item label="联系电话">
							<el-input v-model="ruleForm.phone" placeholder="输入联系电话">
								
							</el-input>
						</el-form-item>
						<el-form-item label="最大容纳人数" prop="max">
							<el-input v-model="ruleForm.max" type="number"></el-input>
						</el-form-item>
						<el-form-item label="图片">
							<el-upload class="avatar-uploader" :action="$URL + '/api/login/upload'"
								:show-file-list="false" :on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<!-- <el-form-item label="是否启用" required>
							<el-switch v-model="ruleForm.switch" :active-value="1" :inactive-value="0" size="small">
							</el-switch>
						</el-form-item> -->
					</el-form>
				</div>
			</div>
			<div class="btnbox">
				<el-button type="" size="mini" class="font14" @click="resetform">关闭</el-button>
				<el-button type="primary" size="mini" class="font14"
					@click="submit">{{ruleForm.id?'保存修改':'保存提交'}}</el-button>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				ruleForm: {
					name: '',
					address: '',
					zuobiao: '',
					max: '',
					// switch: 1,
					username: '',
					phone: '',
					log: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入会议室名称',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入会议室地址',
						trigger: 'blur'
					}],
					zuobiao:[{
						required: true,
						message: '请输入坐标',
						trigger: 'blur'
					}]
				},
				imageUrl: ''
			}
		},
		methods: {
			openmap() {
				window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						console.log(this.ruleForm)
						this.$post({
							url: this.ruleForm.id ? '/api/invest_research_room/edit' : '/api/invest_research_room/add',
							params: this.ruleForm
						}).then((res) => {
							this.$message({
								type: 'success',
								message: '操作成功!'
							})
							this.$parent.gettable()
							this.resetform()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				
			},
			resetform() {
				this.ruleForm = {
					name: '',
					address: '',
					zuobiao: '',
					max: '',
					status: 1,
					log: ''
				}
				this.imageUrl = ''
				this.dialogVisible = false
			},
			handleAvatarSuccess(res, file) {
				this.ruleForm.log = res.fullurl
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			showselect() {
				this.$refs.myselectfather.dialogVisible = true
			},
			selected(e) {
				this.fathername = e.name
				this.form.pid = e.id
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.tlename {
			width: 120px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;

	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-top: 1px solid #eee;
	}

	

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>